<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <!-- Decentraliation group list -->
    <v-layout
      justify-center
      wrap
    >
      <v-flex
        md12
      >
        <material-card
          :title="$t('decentralizationGroup.decentralizationGroupList')"
          color="green"
          flat
          full-width
        >
          <template v-if="hasAction('create_group') || isAdmin()">
            <v-btn
              color="success"
              dark
              style="float: right;"
              @click="onShowModalAddDecentralizationGroup(null)"
            >
              {{ $t('common.add') }}
            </v-btn>
          </template>
          <v-data-table
            v-if="hasAction('view_group') || isAdmin()"
            :no-data-text="$t('common.noDataAvailable')"
            :headers="decentralizationGroupHeaders"
            :items="decentralizationGroupList"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="text--darken-3 font-medium"
                v-text="$t(header.text)"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <tr
                :class="{ 'test-primary': item.id == groupSelected.id }"
                style="cursor: pointer;"
                @click="selectRow(item)">
                <td class="text-xs-center">{{ item.stt }}</td>
                <td style="width: 80%;">{{ item.decentralizationGroupName }}</td>
                <td class="text-xs-center no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="!hasGroupDefault(item)"
                        class="mx-2"
                        fab
                        small
                        color="indigo"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalConfirmRestorePermisison(item)">
                        <v-icon dark>mdi-restore</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.restore') }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="!(hasAction('update_group') || isAdmin())"
                        class="mx-2"
                        fab
                        small
                        color="primary"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalAddDecentralizationGroup(item)">
                        <v-icon dark>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.update') }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        :disabled="hasGroupDefault(item) || !(isAdmin())"
                        class="mx-2"
                        fab
                        small
                        color="red"
                        style="color: #ffffff;"
                        v-on="on"
                        @click="onShowModalDeletedecentralizationGroup(item.id)">
                        <v-icon dark>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('common.delete') }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>
    </v-layout>
    <!-- Decentralization group detail -->
    <v-layout
      v-show="!isNull(groupSelected.id)"
      wrap>
      <v-flex>
        <decentralizationGroupDetail
          ref="decentralizationGroupDetail"
          :group-id="groupSelected.id"
          :group-code="groupSelected.code" />
      </v-flex>
    </v-layout>
    <addDecentralizationGroupModal
      ref="addDecentralizationGroupModal"/>
    <confirmModal
      ref="confirmRestorePermissionModal"
      :title="confirmModalTitle"
      @onConfirm="onRestorePermissionInGroup" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onDeleteGroup"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AddDecentralizationGroupModal from 'Components/AddDecentralizationGroupModal'
import DecentralizationGroupDetail from './GroupDetail'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import ConfirmModal from 'Components/ConfirmModal'
export default {
  components: {
    addDecentralizationGroupModal: AddDecentralizationGroupModal,
    decentralizationGroupDetail: DecentralizationGroupDetail,
    confirmModal: ConfirmModal
  },
  data: () => ({
    decentralizationGroupHeaders: [
      {
        sortable: false,
        text: 'common.nonumber',
        value: 'stt',
        align: 'start'
      },
      {
        sortable: false,
        text: 'groups.groupName',
        value: 'decentralizationGroupName',
        align: 'start'
      },
      {
        sortable: false,
        text: 'common.actions',
        value: 'actions',
        align: 'center'
      }
    ],
    decentralizationGroupList: [],
    groupSelected: {
      id: null,
      code: null
    },
    isShowGroupDetail: true,
    confirmModalTitle: '',
    decentralizationIdSelected: null
  }),
  computed: {
    ...mapGetters([
      'DECENTRALIZATION_GROUP_LIST_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    DECENTRALIZATION_GROUP_LIST_DATA () {
      let data = this.DECENTRALIZATION_GROUP_LIST_DATA
      this.decentralizationGroupList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let decentralizationGroupObj = {
          stt: i + 1,
          id: data[i].id,
          decentralizationGroupName: data[i].name,
          code: data[i].code
        }
        this.decentralizationGroupList.push(decentralizationGroupObj)
      }
    }
  },
  created () {
    this.GET_DECENTRALIZATION_GROUP_LIST()
  },
  methods: {
    // Check is admin
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Show modal confirm
     */
    onShowModalConfirmRestorePermisison: function (item) {
      this.groupSelected.code = item.code
      this.confirmModalTitle = this.$t('groups.confirmRestorePermissionTitle')
      this.$refs.confirmRestorePermissionModal.onShowModal()
    },
    /**
     * Restore permission in group
     */
    onRestorePermissionInGroup: function () {
      let filter = {
        code: this.groupSelected.code
      }
      this.RESTORE_PERMISSION_IN_GROUP(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.$refs.decentralizationGroupDetail.restorePermissionSuccess(this.groupSelected.id)
        }.bind(this)
      ).catch(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('login.somethingWentWrong'),
            styleType: ToastType.ERROR
          })
        }.bind(this)
      )
    },
    /**
     * Has restore
     */
    hasGroupDefault: function (item) {
      if (item.code === 'system_admin' || item.code === 'admin_supplier' || item.code === 'staff_supplier' || item.code === 'admin_agent' || item.code === 'staff_agent' || item.code === 'admin_site' || item.code === 'staff_site') {
        return true
      }
      return false
    },
    /**
     * Has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Retrieve user info
     */
    retrieveUserInfo: function () {
      this.GET_USER_DATA()
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
    * Select row
    */
    selectRow: function (item) {
      this.groupSelected.id = item.id
      this.groupSelected.code = item.code
      this.$refs.decentralizationGroupDetail.checkGroupDefault(item.code)
    },
    onShowModalDeletedecentralizationGroup: function (id) {
      this.confirmModalTitle = this.$t('decentralizationGroup.onShowModalDeletedecentralizationGroupTitle')
      this.decentralizationIdSelected = id
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Delete group
     */
    onDeleteGroup: function () {
      this.DELETE_DECENTRALIZATION_GROUP({ id: this.decentralizationIdSelected }).then(
        function () {
          this.groupSelected.id = null
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.retrieveUserInfo()
          this.GET_DECENTRALIZATION_GROUP_LIST()
        }.bind(this)
      )
    },
    /**
     * Show modal add decentralization group
     */
    onShowModalAddDecentralizationGroup: function (group) {
      this.$refs.addDecentralizationGroupModal.onShowModal(group)
    },
    ...mapActions([
      'GET_DECENTRALIZATION_GROUP_LIST',
      'DELETE_DECENTRALIZATION_GROUP',
      'ON_SHOW_TOAST',
      'GET_USER_DATA',
      'RESTORE_PERMISSION_IN_GROUP'
    ])
  }
}
</script>
<style lang="scss" scoped>
table.v-table tbody td:first-child, table.v-table tbody td:not(:first-child), table.v-table tbody th:first-child, table.v-table tbody th:not(:first-child), table.v-table thead td:first-child, table.v-table thead td:not(:first-child), table.v-table thead th:first-child, table.v-table thead th:not(:first-child) {
  padding: 5px 0px 5px 0px !important;
}
/deep/ .test-primary {
  background-color: #c7c7c7c7 !important;
}
/deep/ .test-primary:hover {
  background-color: #c7c7c7c7 !important;
}
/deep/ .theme--light.v-list {
  background-color: transparent;
}
</style>
