var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"padding-top":"0"},attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"justify-center":"","wrap":""}},[_c('v-flex',{attrs:{"md12":""}},[_c('material-card',{attrs:{"title":_vm.$t('decentralizationGroup.decentralizationGroupList'),"color":"green","flat":"","full-width":""}},[(_vm.hasAction('create_group') || _vm.isAdmin())?[_c('v-btn',{staticStyle:{"float":"right"},attrs:{"color":"success","dark":""},on:{"click":function($event){return _vm.onShowModalAddDecentralizationGroup(null)}}},[_vm._v(" "+_vm._s(_vm.$t('common.add'))+" ")])]:_vm._e(),(_vm.hasAction('view_group') || _vm.isAdmin())?_c('v-data-table',{attrs:{"no-data-text":_vm.$t('common.noDataAvailable'),"headers":_vm.decentralizationGroupHeaders,"items":_vm.decentralizationGroupList,"hide-actions":""},scopedSlots:_vm._u([{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('span',{staticClass:"text--darken-3 font-medium",domProps:{"textContent":_vm._s(_vm.$t(header.text))}})]}},{key:"items",fn:function(ref){
var item = ref.item;
return [_c('tr',{class:{ 'test-primary': item.id == _vm.groupSelected.id },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.selectRow(item)}}},[_c('td',{staticClass:"text-xs-center"},[_vm._v(_vm._s(item.stt))]),_c('td',{staticStyle:{"width":"80%"}},[_vm._v(_vm._s(item.decentralizationGroupName))]),_c('td',{staticClass:"text-xs-center no-wrap"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"disabled":!_vm.hasGroupDefault(item),"fab":"","small":"","color":"indigo"},on:{"click":function($event){return _vm.onShowModalConfirmRestorePermisison(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-restore")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.restore')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"disabled":!(_vm.hasAction('update_group') || _vm.isAdmin()),"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onShowModalAddDecentralizationGroup(item)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.update')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",staticStyle:{"color":"#ffffff"},attrs:{"disabled":_vm.hasGroupDefault(item) || !(_vm.isAdmin()),"fab":"","small":"","color":"red"},on:{"click":function($event){return _vm.onShowModalDeletedecentralizationGroup(item.id)}}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])],1)])]}}],null,false,2562518965)}):_vm._e()],2)],1)],1),_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNull(_vm.groupSelected.id)),expression:"!isNull(groupSelected.id)"}],attrs:{"wrap":""}},[_c('v-flex',[_c('decentralizationGroupDetail',{ref:"decentralizationGroupDetail",attrs:{"group-id":_vm.groupSelected.id,"group-code":_vm.groupSelected.code}})],1)],1),_c('addDecentralizationGroupModal',{ref:"addDecentralizationGroupModal"}),_c('confirmModal',{ref:"confirmRestorePermissionModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onRestorePermissionInGroup}}),_vm._l((_vm.GET_SNACK_BAR),function(snackbar){return _c('snack-bar',{key:snackbar.id,attrs:{"item":snackbar}})}),_c('confirm-modal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"onConfirm":_vm.onDeleteGroup}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }