<template>
  <v-layout>
    <v-flex
      md12
      lg6>
      <material-card
        :title="$t('groups.availableActions')"
        color="green"
        flat
        full-width
      >
        <v-list three-line>
          <v-list-tile
            v-for="(action, index) in availableActions"
            :key="action + index"
            :class="index < (availableActions.length - 1) ? 'mb-3' : ''">
            <v-list-tile-action v-if="!hasGroupDefault || isSuperAdmin()">
              <v-checkbox
                v-model="actionAvailableSelected"
                :value="action.id"
                color="green"
              />
            </v-list-tile-action>
            <v-list-tile-title>
              {{ $t(action.name) }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
        <v-tooltip
          v-if="actionAvailableSelected.length > 0"
          top>
          <template v-slot:activator="{ on }">
            <div style="text-align: right;">
              <v-btn
                small
                color="success"
                style="color: #ffffff;"
                v-on="on"
                @click="onAddActionToGroup()">
                <v-icon dark>mdi-plus</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('common.delete') }}</span>
        </v-tooltip>
      </material-card>
    </v-flex>
    <v-flex
      md12
      lg6>
      <material-card
        :title="$t('groups.assignedActions')"
        color="green"
        flat
        full-width
      >
        <v-list three-line>
          <v-list-tile
            v-for="(action, index) in assignedActions"
            :key="action + index"
            :class="index < (assignedActions.length - 1) ? 'mb-3' : ''">
            <v-list-tile-action>
              <v-checkbox
                v-model="actionAssignedSelected"
                :value="action.id"
                color="green"
              />
            </v-list-tile-action>
            <v-list-tile-title>
              {{ $t(action.name) }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
        <v-tooltip
          v-if="actionAssignedSelected.length > 0"
          top>
          <template v-slot:activator="{ on }">
            <div style="text-align: right;">
              <v-btn
                small
                color="red"
                style="color: #ffffff;"
                v-on="on"
                @click="onRemoveActionFromGroup()">
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('common.delete') }}</span>
        </v-tooltip>
      </material-card>
    </v-flex>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"/>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
export default {
  props: {
    groupId: {
      type: Number,
      default: () => {
        return null
      }
    },
    groupCode: {
      type: String,
      default: () => {
        return null
      }
    }
  },
  data: () => ({
    availableActions: [],
    actionAvailableSelected: [],
    assignedActions: [],
    actionAssignedSelected: [],
    hasGroupDefault: true
  }),
  computed: {
    ...mapGetters([
      'DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP_DATA',
      'GET_SNACK_BAR',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP_DATA: function () {
      let data = this.DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP_DATA.data
      // Set default value for available, assigned actions
      this.actionAvailableSelected = []
      this.actionAssignedSelected = []
      // Set value available, assigned action for data request
      let availableActions = data.available_actions
      let assignedActions = data.action_assigned
      this.availableActions = availableActions
      this.assignedActions = assignedActions
    },
    groupId: function () {
      this.getDecentralizationActionInOutSideGroup()
    }
    // groupCode: function () {
    //   if (this.groupCode === 'system_admin' || this.groupCode === 'admin_supplier' || this.groupCode === 'staff_supplier' || this.groupCode === 'admin_agent' || this.groupCode === 'staff_agent') {
    //     this.hasGroupDefault = true
    //   } else {
    //     this.hasGroupDefault = false
    //   }
    // }
  },
  created () {
    this.getDecentralizationActionInOutSideGroup()
  },
  methods: {
    /**
     * Check group default
     */
    checkGroupDefault: function (groupCode) {
      if (groupCode === 'system_admin' || groupCode === 'admin_supplier' || groupCode === 'staff_supplier' || groupCode === 'admin_agent' || groupCode === 'staff_agent') {
        this.hasGroupDefault = true
      } else {
        this.hasGroupDefault = false
      }
    },
    /**
     * Check is super admin
     */
    isSuperAdmin: function () {
      return functionUtils.isSuperAdmin(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Restore permission success
     */
    restorePermissionSuccess: function (groupId) {
      this.groupId = groupId
      this.getDecentralizationActionInOutSideGroup()
    },
    /**
     * Retrieve user info
     */
    retrieveUserInfo: function () {
      this.GET_USER_DATA()
    },
    /**
     * Remove action from group
     */
    onRemoveActionFromGroup: function () {
      let filter = {
        params: {
          actionIds: this.actionAssignedSelected
        }
      }
      this.DELETE_ACTION_FROM_GROUP({ id: this.groupId, ...filter }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.retrieveUserInfo()
          this.getDecentralizationActionInOutSideGroup()
        }.bind(this)
      )
    },
    /**
    * Add action to group
    **/
    onAddActionToGroup: function () {
      let filter = {
        groupId: this.groupId,
        actionIds: this.actionAvailableSelected
      }
      this.ADD_ACTION_TO_GROUP(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
          this.retrieveUserInfo()
          this.getDecentralizationActionInOutSideGroup()
        }.bind(this)
      )
    },
    /*
    * Get dencentralization action in out side group
    **/
    getDecentralizationActionInOutSideGroup: function () {
      let filter = {
        params: {
          groupId: this.groupId
        }
      }
      this.GET_DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP(filter)
    },
    ...mapActions([
      'GET_DECENTRALIZATION_ACTION_IN_OUT_SIDE_GROUP',
      'ADD_ACTION_TO_GROUP',
      'DELETE_ACTION_FROM_GROUP',
      'ON_SHOW_TOAST',
      'GET_USER_DATA'
    ])
  }
}
</script>
<style scoped>
.v-list {
  height: 300px;
  overflow-y: auto;
}
</style>
